<template>
  <section class="relative">
    <div
      class="fixed right-0 bottom-0 h-full flex flex-col justify-end items-end z-10"
    >
      <img
        class="w-auto h-96 mb-20 mb-20 md:mb-auto md:h-screen md:pt-48 transition-all duration-700 p-4 pr-0 transform translate-x-1"
        src="@/assets/images/KuengCaputoLogo.svg"
        alt="Kueng Caputo"
        :class="{ small: scrolled }"
      />
    </div>
    <nav
      class="mx-auto relative z-20 w-full max-w-screen-xl grid grid-cols-4 gap-4 items-start p-4 md:pt-8"
    >
      <div class="text-xs md:text-2xs">
        <router-link to="/">DE</router-link> / FR
      </div>
      <div class="text-center col-span-2">
        <div class="mb-1 md:mb-4 uppercase flex justify-center">
          <img
            class="h-4 md:h-10 max-w-none w-auto"
            src="../assets/Logo.svg"
            alt="GLOBUS Logo"
          />
        </div>
      </div>
      <div class="flex justify-end font-gill tracking-wide col-span-1">
        <a
          href="https://www.globus.ch"
          class="lowercase inline-block no-wrap text-xs md:text-2xs bg-transparent text-black px-2 md:px-4 py-1 border border-black"
          target="_blank"
          >zu globus.ch</a
        >
      </div>
    </nav>
    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end px-4 h-90vh md:h-auto pb-8 md:pb-0"
    >
      <div
        class="pt-24 md:pt-40 font-medium text-mintro md:text-intro col-start-1 md:col-start-2 col-end-9"
      >
        À travers ses créations, le duo zurichois Kueng Caputo cherche à
        préserver l’artisanat. Pour Globus, les créatrices imaginent des
        vitrines aux mille et une merveilles.
      </div>
      <div
        class="col-start-1 col-end-12 md:col-start-1 md:col-end-7 w-80 md:w-full md:h-full pt-80 md:pt-20"
      >
        <img alt="Kooperation logo" src="../assets/Kooperation_Logo.svg" />
      </div>
    </article>
    <article
      class="mx-auto max-w-screen-xl px-0 md:px-4 grid grid-cols-12 gap-4 items-start pt-8 md:pt-28"
    >
      <div
        class="order-2 md:order-1 pl-4 md:pl-0 col-start-1 md:col-start-2 col-end-10 md:col-end-6 mb-8 md:mb-0 text-mbase md:text-base"
      >
        Dans l’atelier de Kueng Caputo, dans le 9e arrondissement de Zurich, les
        créations s’étalent à perte de vue: films multicolores scintillants
        suspendus au plafond, piles de boîtes, prototypes en carton, étagères
        remplies de classeurs... L’un d’eux, qui porte la mention «On ice»,
        recense les projets encore au stade d’idée. Officiellement, le duo de
        designers est né en 2008. Sarah Kueng et Lovis Caputo se sont
        rencontrées avant leurs études à la Haute École d’art de Zurich, ont
        commencé à créer ensemble, et n’ont jamais arrêté depuis. Depuis, le duo
        oscille avec brio entre art et design. Car ces deux créatives ont su
        s’affranchir de toute étiquette.
      </div>
      <div
        class="order-1 md:order-2 px-4 col-start-1 md:col-start-7 col-end-13 md:col-end-11 md:sticky md:top-12 pb-8 md:pb-auto flex justify-center overflow:hidden"
      >
        <div
          class="w-80 h-80 flex-grow-0 flex-shrink-0 bg-white rounded-full relative"
          :style="{ left: posX + 'px' }"
        >
          <div class="flex flex-col justify-center h-full">
            <div class="text-center leading-4 tracking-wide">
              SPECIAL<br />EDITION
            </div>
            <div class="text-center pt-6 text-large">Kueng Caputo</div>
            <div class="text-center pb-12 text-intro leading-7 font-medium">
              CARPETS
            </div>
            <div class="text-center">
              <div
                v-scroll-to="'#order'"
                class="text-small cursor-pointer inline-block bg-transparent text-center px-4 py-2 border border-black"
                target="_blank"
              >
                Pre-order now
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="order-3 col-start-1 md:col-start-2 md:col-end-7 col-end-13 w-full pt-12 md:pt-20"
      >
        <img alt="" src="../assets/img/Main-Image.jpg" />
      </div>
    </article>
    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end pt-8 md:pt-28"
    >
      <div
        class="order-2 md:order-1 col-start-3 md:col-start-2 col-end-11 md:col-end-5 flex flex-col justify-center md:w-full"
      >
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_152_sRGB.jpg"
        />
      </div>
      <div
        class="order-1 md:order-2 pl-4 md:pl-0 col-start-1 md:col-start-6 col-end-10 md:col-end-10 py-8 md:pb-0 md:py-auto text-mbase md:text-base space-y-4"
      >
        <p>
          Leurs projets ont de quoi surprendre. Kueng Caputo travaille la
          pierre, l’émail, les granulés de caoutchouc colorés et la porcelaine
          pour concevoir des tabourets, des lampes, des bols et des vases, ou
          encore aménager des intérieurs. En 2019, au salon Design Miami, le duo
          a présenté du mobilier et un sac pour la maison de mode italienne
          Fendi. Le point commun entre tous ces projets? Une collaboration avec
          des artisans chevronnés. D’ailleurs, les deux touche-à-tout n’hésitent
          pas à travailler elles aussi les matières et à acquérir des
          techniques. Jusqu’à atteindre le résultat escompté au fil de leurs
          longues discussions. L’an dernier, elles ont obtenu le Grand Prix
          Design de Suisse.
        </p>
        <p>
          Mais leurs produits haut de gamme ne se contentent pas d’être
          esthétiques. Il suffit de discuter avec les deux créatrices pour
          aborder rapidement le thème de la responsabilité. Celle du designer,
          mais aussi celle du consommateur. La discussion porte sur la juste
          rémunération, le prix et la valeur des choses. Mais aussi sur leur
          dernière prouesse pour Globus. Dans les semaines à venir, les
          créatrices transformeront nos vitrines en véritables chambres des
          merveilles, partout en Suisse.
        </p>
      </div>
    </article>

    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="pt-14 md:pt-28 pl-4 md:pl-0 col-start-1 md:col-start-2 col-end-10 md:col-end-6 space-y-4 text-mbase md:text-base"
      >
        <p>
          VOUS EST-IL PLUTÔT INHABITUEL D’AVOIR CARTE BLANCHE?
          <br />
          Kueng Caputo: En tant que créatrices, nous avons davantage l’habitude
          de recevoir des commandes concrètes. Ce qui nous motive tout
          particulièrement dans ce projet, c’est la surface de la vitrine, qui
          revêt une importance nouvelle en ce moment. De nombreuses institutions
          sont aujourd’hui fermées, mais il est toujours possible d’admirer une
          vitrine. Nos travaux pourront ainsi toucher un public plus large.
        </p>

        <p>
          UN GRAND NOMBRE DE VOS CRÉATIONS SONT PLUTÔT CRITIQUES ENVERS LA
          GRANDE CONSOMMATION. QUELLE EST LA PLACE DE CE PROJET DANS LA VITRINE
          D’UN GRAND MAGASIN?
          <br />
          Ce projet n’a rien de politique. Après tout, de quoi les gens ont-ils
          envie à l’heure actuelle? D’être émerveillés. De vivre une -expérience
          palpable, qui fait appel aux sens. En ce moment, tout est axé sur la
          science. Il n’y a plus de place laissée au hasard, aux visions.
          Peut-être parviendrons-nous, à travers notre projet, à susciter une
          prise de conscience. À surprendre les gens. Le plus beau compliment
          serait que notre travail donne envie de passer à l’action, au lieu de
          se contenter de consommer.
        </p>
      </div>
      <div
        class="col-start-3 md:col-start-7 col-end-11 md:col-end-10 w-full pt-8 md:pt-0"
      >
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_085_sRGB.jpg"
        />
      </div>
    </article>

    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-start pt-14 md:pt-28"
    >
      <div class="col-start-1 md:col-start-2 col-end-13 md:col-end-10 w-full">
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_190_sRGB.jpg"
        />
      </div>
    </article>

    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end pt-14 md:pt-28"
    >
      <div
        class="col-start-3 md:col-start-2 col-end-11 md:col-end-5 pb-8 md:pb-0"
      >
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_156_sRGB.jpg"
        />
      </div>
      <div
        class="pl-4 md:pl-0 col-start-1 md:col-start-6 col-end-10 md:col-end-10 text-mbase md:text-base space-y-4"
      >
        <p>
          VOUS TRAVAILLEZ EN ÉQUIPE DEPUIS LE DÉBUT. L’INDIVIDUALISME EST-IL EN
          VOIE DE DISPARITION?
          <br />Nous sommes convaincues par la force du collectif. Le dialogue
          est le plus important à nos yeux. En équipe, on a plus de distance
          face à son travail. Même lorsque cela ne fonctionne pas. Après tout,
          il ne s’agit que d’une chaise ou d’une table, ce n’est pas une
          question de vie ou de mort. Cela arrive tous les jours qu’un projet
          échoue. Mais le temps qui y a été consacré n’est jamais perdu, -jamais
          inutile. Il n’y a que ceux qui n’osent rien qui n’échouent jamais.
        </p>
        <p>
          JUSQU’OÙ POUSSEZ-VOUS VOS -RÉFLEXIONS SUR VOTRE TRAVAIL? QU’EST-CE QUI
          LE REND PERTINENT?<br />
          Il nous tient à cœur d’attirer l’attention sur la valeur de
          l’artisanat. Un grand nombre de ces -savoir-faire disparaissent. En
          Suisse, nous ne sommes presque plus en mesure de produire quoi que ce
          soit. La plupart du temps, ce sont des -seniors qui instruisent les
          collaborateurs. Nous avons fait preuve de -négligence en laissant se
          perdre un -savoir-faire précieux. Le consommateur doit comprendre le
          nombre d’étapes nécessaires pour -fabriquer un produit. Combien de
          gens y ont travaillé. Car la production de masse se fait toujours au
          -détriment de ceux qui -fabriquent les choses. Les artisans avec
          lesquels nous travaillons sont des -experts. Nous avons un immense
          respect envers eux et passons beaucoup de temps à les observer.
          Derrière chaque -matière, chaque procédé se cache tout un univers.
          Nous devons réapprendre à apprécier ces savoirs à leur juste valeur.
        </p>
        <p>
          ET À APPRÉCIER LA VALEUR DES BONS MOMENTS. REVENONS AU PROJET POUR
          GLOBUS: QU’ALLONS-NOUS POUVOIR ADMIRER?
          <br />Nous créons des vitrines qui racontent de petites histoires pour
          huit magasins, répartis dans toute la Suisse. Ce qui paraît simple de
          prime abord dissimule souvent une technique ingénieuse. Nos vitrines
          parviendront peut-être à émerveiller. Car notre monde est encore plein
          de merveilles.
        </p>
      </div>
    </article>

    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="pt-20 md:pt-28 col-start-2 md:col-start-2 col-end-12 font-medium"
      >
        MAKING-OF
      </div>
      <div
        class="col-start-1 md:col-start-2 col-end-13 md:col-end-10 w-full h-full relative"
        style="padding-top:56.25%"
      >
        <iframe
          src="https://player.vimeo.com/video/516274489?title=0&byline=0&portrait=0"
          width="640"
          height="360"
          class="absolute block inset-0 w-full h-full"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </article>

    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end text-mbase md:text-base"
    >
      <div
        class="pl-4 md:pl-0 col-start-1 md:col-start-2 col-end-10 md:col-end-10 pt-8 md:pt-auto pb-14"
      >
        Comment cela se passe-t-il quand des idées sont concrétisées? Dans la
        vidéo, découvrez Kueng Caputo à l’œuvre. Nous accompagnons les artistes
        à l’un de leurs endroits préférés de Zurich. Sarah Kueng et Lovis Caputo
        nous dévoilent ce qui leur a plu dans ce projet pour Globus et en quoi
        elles forment une si bonne équipe. Elles nous laissent également jeter
        un coup d’œil dans leur studio; l’occasion, pour nous, de voir les deux
        têtes créatives à l’action, en plein bricolage.
      </div>
    </article>

    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="col-start-1 col-end-12 md:col-start-1 md:col-end-7 w-80 md:w-full md:h-full pl-4 md:pt-20"
      >
        <img alt="Kooperation logo" src="../assets/Kooperation_Logo.svg" />
      </div>
    </article>
    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="col-start-2 md:col-start-1 col-end-7 md:col-end-5 md:sticky pt-8 md:top-12"
      >
        <div class="w-80 h-80 bg-white rounded-full">
          <div class="flex flex-col justify-center h-full">
            <div class="text-center leading-4 tracking-wide">
              SPECIAL<br />EDITION
            </div>
            <div class="text-center pt-6 text-large">Kueng Caputo</div>
            <div class="text-center pb-12 text-intro leading-7 font-medium">
              CARPETS
            </div>
            <div class="text-center">
              <div
                class="text-small inline-block bg-transparent text-center px-4 py-2"
                target="_blank"
              >
                Pre-order here
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="preorder"
        class="pl-4 md:pl-0  pt-12 md:pt-20 col-start-1 md:col-start-6 col-end-10 md:col-end-10 text-mbase md:text-base space-y-4"
      >
        <span class="uppercase font-medium">Édition spéciale</span>
        <p>
          Les travaux de Kueng Caputo s’admirent dans les galeries et les musées
          – et peut-être bientôt chez vous également. Une touche de couleur
          pourrait être la bienvenue dans votre intérieur? Dans ce cas,
          procurez-vous l’un des tapis en édition limitée imaginés par les
          artistes suisses. En l’occurrence, un tapis en granulés de caoutchouc!
          Le duo a consacré deux ans à ce matériau d’un genre nouveau, qui tient
          désormais la vedette dans ce projet pour Globus. Kueng Caputo en a
          fait des monticules colorés. Après le démontage de l’installation, ils
          se mueront en tapis aux couleurs vives – l’art du recyclage dans sa
          plus belle expression. Les pièces pourront être livrées à partir
          d’août et perpétueront le happening artistique chez vous. Chaque
          création est unique et symbolise un véritable savoir-faire artisanal
          ainsi que l’audace d’oser la nouveauté. Les trois éditions se
          composent chacune de 30 tapis numérotés et signés.
        </p>
      </div>
    </article>

    <div id="order" class="py-20 col-start-1 col-end-12">
      <carousel
        @page-change="changeSlide"
        :autoPlay="true"
        :scrollPerPage="false"
        :perPageCustom="[
          [375, 1],
          [768, 3],
        ]"
        :navigationEnabled="true"
        :navigationNextLabel="''"
        :navigationPrevLabel="''"
        :paginationEnabled="false"
      >
        <slide v-if="!isMobile" class="hidden md:block"></slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-1.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              L’onda 01<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 90cm<br />
              CHF 950
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-2.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              L’onda 02<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 90cm<br />
              CHF 950
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-3.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              il cubo 01<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 180cm<br />
              CHF 1’200
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-5.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              il cubo 02<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 180cm<br />
              CHF 1’200
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-4.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              il cubo 03<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 180cm<br />
              CHF 1’200
            </div>
          </div>
        </slide>

        <slide v-if="!isMobile" class="hidden md:block"></slide>
      </carousel>
      <div class="col-start-1 col-end-3 text-center pt-10 pb-56">
        <a
          :href="
            'mailto:salut@voile.studio?subject=KUENG CAPUTO x GLOBUS, Limited Edition&body=' +
              email
          "
          class="text-small bg-transparent text-center px-6 py-2 border border-black"
          target="_blank"
          >Pre-order now
        </a>
      </div>
      <section
        class="max-w-screen-lg mx-auto px-8 md:px-4 pb-16 text-2xs leading-5 font-sans"
      >
        <div class="flex flex-wrap">
          <div class="mr-4 w-full md:w-auto">
            <span class="font-medium">HERAUSGEBERIN</span> Magazine zum Globus
            AG
          </div>
          <div class="w-full mr-4 md:w-auto">
            <span class="font-medium whitespace-no-wrap"
              >Creative Direction & Realisation
            </span>
            <a href="https://voile.studio" target="_blank">voile.studio</a>
          </div>
          <div class="w-full md:w-auto">
            <span class="font-medium whitespace-no-wrap">Copy </span>
            Leoni Hof
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="mr-4 w-full md:w-auto">
            <span class="font-medium">Photographer</span> Flavio Karrer
          </div>
          <div class="mr-4 w-full md:w-auto">
            <span class="font-medium">Videographer</span> Stefan Grah
          </div>
          <div class="w-full md:w-auto">
            <span class="font-medium">Programming</span> Frederik
            Mahler-Andersen & Hu Jung-Ting
          </div>
        </div>
        <div>@ All Rights Reserved</div>
      </section>
    </div>
  </section>
</template>

<script>
import mails from "@/emails.json";
export default {
  name: "Home",
  data() {
    return {
      slide: 0,
      scrolled: false,
      posX: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
  computed: {
    email() {
      return mails[this.slide];
    },
    isMobile() {
      if (window.innerWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    changeSlide(data) {
      this.slide = data;
    },
    scroll() {
      if (window.scrollY > 150) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
      if (window.innerWidth > 768) {
        var start = window.innerHeight;
        var end = window.innerHeight * 2;
        if (window.scrollY > start && window.scrollY < end) {
          this.posX = (window.scrollY / start - 1) * 100;
          console.log(this.posX);
        }
      }
    },
  },
};
</script>

<style>
.VueCarousel-navigation-button {
  top: auto !important;
}
.VueCarousel-navigation-next {
  transform: translate(-100%, -50%) !important;
  bottom: 0;
  z-index: 100;
}
.VueCarousel-navigation-next:before {
  content: "";
  width: 4rem;
  height: 4rem;
  background-image: url("../assets/images/right-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.VueCarousel-navigation-prev {
  transform: translate(100%, -50%) !important;
  bottom: 0;
  z-index: 100;
}
.VueCarousel-navigation-prev:before {
  content: "";
  width: 4rem;
  height: 4rem;
  /* background: black; */
  background-image: url("../assets/images/left-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  position: absolute;
  bottom: 0;
  left: 1rem;
}
@media only screen and (min-width: 768px) {
  .VueCarousel-navigation-prev:before {
    left: 30vw;
  }
  .VueCarousel-navigation-next:before {
    right: 30vw;
  }
}
</style>
